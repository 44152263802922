import React from 'react';
import {
    Box,
    Container,
    Typography,
    Grid,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import dashboardService from '../services/dashboard';
import ReactApexChart from 'react-apexcharts';
import Counter from '../components/Counter';  // Import Counter component

const Dashboard = () => {
    const [username, setUsername] = useState('');
    const [memberSince, setMemberSince] = useState('');
    const [vintedTasks, setVintedTasks] = useState(0)
    const [totalVintedTasks, setTotalVintedTasks] = useState(0)

    const [loading, setLoading] = useState(true);
    const auth = useAuth();

    const [options, setOptions] = useState({
        chart: {
            type: 'pie',
            width: 20,  // Adjust width here
            height: 20, // Adjust height here
        },
        fill: {
            type: 'gradient',
        },
        labels: ['Adidas', 'Nike', 'Chrome hearts'],
        legend: {
            show: true,
        },
        // ...other chart options
    });

    const [series, setSeries] = useState([44, 55, 41]);


    useEffect(() => {
        dashboardService
            .getDashboardInfo(auth.token)
            .then((info) => {
                if (info ) {
                    const { username, memberSince, vintedTaskCount, totalFilterCount } = info;
                    setUsername(username ? username : '');
                    setVintedTasks(vintedTaskCount ? vintedTaskCount : 0);
                    if (memberSince && !isNaN(new Date(memberSince))) {
                      setMemberSince(new Date(memberSince).toLocaleDateString());
                    } else {
                        setMemberSince('');
                    }
                    setTotalVintedTasks(totalFilterCount ? totalFilterCount : 0);
                }
            });
        setLoading(false);
    }, [auth.token]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box component='main' sx={{ flexGrow: 1, py: 8, position: 'relative' }}>
            <Container maxWidth='lg'>
                <Typography sx={{ mb: 3 }} variant='h4'>
                    VINTXPRESS DASHBOARD
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Counter count={username} label="Usuario 🙋‍♂️" />

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Counter count={vintedTasks} label="Tus filtros activos 🕵️" />

                    </Grid>
                    <Grid item xs={12} >
                    <Counter count={memberSince} label="Miembro desde 📅" />
                    </Grid>
                    <Grid item xs={12} >
                    <Counter count={totalVintedTasks} label="Total filtros VintXpress 📈" />
                    </Grid>
                    {/* <Grid item xs={12} sm={4}>
                        <Counter count={12310244} label="VINTXPRESS WEBHOOKS" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Counter count={56789} label="YOUR WEBHOOKS" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Counter count={98765} label="YOUR WEBHOOKS THIS MONTH" />
                    </Grid> */}

                </Grid>
            </Container>
        </Box>
    );
};

export default Dashboard;