import React, { useState } from 'react';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  Chip,
  useMediaQuery,
  Container,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import UpdateToken from './token-toolbars/updateToken'; // Adjust the path as needed

const formatTokenValue = (value) => {
  if (value.length > 14) {
    return `${value.substring(0, 4)}....${value.substring(value.length - 10)}`;
  }
  return value;
};




const TokensList = ({token,  tokens, handleRemoveToken , callback}) => {
  const [selectedToken, setSelectedToken] = useState(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const handleEditToken = (token) => {
    setSelectedToken(token);
    setIsUpdateModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsUpdateModalOpen(false);
    setSelectedToken(null);
  };


  return (
    <Container>
      <Card>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Token Name</TableCell>
              <TableCell>Token Value</TableCell>
              <TableCell>Principal</TableCell>{" "}
              {/* New column for main token indicator */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tokens.map((token) => (
              <TableRow
                key={token.id}
                onClick={() => handleEditToken(token)}
                style={{ cursor: "pointer" }}
              >
                <TableCell>{token.name}</TableCell>
                <TableCell>{formatTokenValue(token.value)}</TableCell>
                <TableCell>
                  {token.isMain && "⭐"} {/* Show star icon for main token */}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleRemoveToken(token.id)}>
                    <ClearIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}

          </TableBody>
          
        </Table>
        {selectedToken && (
        <UpdateToken
        token = {token}
          tokenData={selectedToken}
          open={isUpdateModalOpen}
          handleClose={handleCloseModal}
          callback={callback}
        />
      )}
      </Card>

    </Container>
  );
};

export default TokensList;
