import * as React from 'react';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { DashboardLayout } from './components/layout/dashboard-layout';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider, RequireAuth } from './hooks/useAuth';
import { Role } from './roles';


import LogIn from './pages/LogIn';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';
import VintedFilters from './pages/Vinted/VintedFilters';
import VintedTokens from './pages/Vinted/Tokens';
import AutobuyPage from './pages/Vinted/Autobuy';

import Callback from './pages/Callback';

export default function App() {
    return (
      <>

      <AuthProvider>
        <ThemeProvider theme={theme}>

          <Routes>
            <Route
              element={
                <>
                  <DashboardLayout />
                </>
              }
            >
              <Route
                path="/"
                element={
                  <RequireAuth roles={[Role.ADMIN, Role.USER]}>

                    <Dashboard />
                    </RequireAuth>
                }
              />


            <Route path="/callback/whop" element={<Callback />} />

              <Route
                path="vinted/filters"
                element={
                  <RequireAuth roles={[Role.ADMIN, Role.USER]}>

                    <VintedFilters />
                    </RequireAuth>
                }
              />
              <Route
                path="vinted/tokens"
                element={
                  <RequireAuth roles={[Role.ADMIN, Role.USER]}>

                    <VintedTokens />
                    </RequireAuth>
                }
              />
            </Route>
            <Route
            path="vinted/autobuy"
            element={
              <RequireAuth roles={[Role.ADMIN, Role.USER]}>
                <AutobuyPage />
              </RequireAuth>
            }
          />

        {/* Add other routes as needed */}
            <Route path="/login" element={<LogIn />} />
            <Route path="*" element={<NotFound />} />
          </Routes>

        </ThemeProvider>
        </AuthProvider>

            </>


  );
}
