import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_API_ENDPOINT}/api/dashboard`;



const getDashboardInfo = async(token)=> {
    const { data } = await axios({
        url: '/',
        method: 'get',
        baseURL: baseUrl,
        headers: { Authorization: `Bearer ${token}` },
    });
    return data;
}

const dashboardService = { getDashboardInfo};

export default dashboardService;
