import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

const login = async (code) => {

    try {

        const response = await axios({
            url: '/api/auth/callback/whop',
            method: 'post',
            baseURL: baseUrl,
            data: {
                code: code.code,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const loginService = { login };

export default loginService;
