import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  LinearProgress,
  Stack,
  Alert,
} from "@mui/material";
import { AddFilter } from "../../components/filter-toolbars/addFilter";
import FiltersList from "../../components/filters-list";
import vintedService from "../../services/vinted";
import { useAuth } from "../../hooks/useAuth";

const fields = [
  "brand",
  "max price",
  "min stars",
  "status",
  "categories",
  "Positive Keywords",
  "Negative Keywords",
];

const VintedFilter = () => {
  const [filters, setFilters] = useState([]);
  const [update, setUpdate] = useState(false);
  const [brandsList, setBrandsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const auth = useAuth();

  const handleCallback = (state) => {
    if (state) {
      vintedService.getAllFiltersTask(auth.token)
        .then((response) => {
          setFilters(response);
        })
        .catch(error => {
          console.error("Failed to fetch filters:", error);
        });
    }
  };

  const handleRemoveFilter = async (filterId) => {
    const response = await vintedService.deleteOldFilterTask(
      filterId,
      auth.token
    );
    if (response === 204) {
      const updatedFilters = filters.filter((filter) => filter.id !== filterId);
      setFilters(updatedFilters);
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 1500);
    }
  };

  useEffect(() => {
    setLoading(true);
    vintedService.getAllFiltersTask(auth.token).then((response) => {
      // Directly set the filters as returned without any modifications
      setFilters(response);
      setLoading(false);
    })
    if (brandsList.length === 0) {
        vintedService.getBrandsTask(auth.token).then((response) => {
            setBrandsList(response.map((brand) => brand.name));
        });
    }
    ;
}, [update, auth.token, brandsList]);

  return (
    <div className="scrollable-container">
      {loading ? (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </Box>
      ) : (
<Box
  component="main"
  sx={{
    flexGrow: 1,
    py: 8,
    position: "relative",
    overflowX: "auto", // Enable horizontal scrolling
    maxWidth: "100%", // Ensure the content can be wider than the viewport
  }}
>
          {alert && (
            <Box
              sx={{
                position: "absolute",
                top: "64px",
                left: "0",
                right: "0",
                margin: "auto",
                zIndex: "1",
              }}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%" }}
                spacing={2}
              >
                <Alert severity="success">Successfully removed filter!</Alert>
              </Stack>
            </Box>
          )}
          <Container maxWidth="lg">
            <AddFilter
              token={auth.token}
              callback={handleCallback}
              brandsList={brandsList}
            />
            <Box sx={{ mt: 3 }}>
              <FiltersList
                filters={filters}
                fields={fields}
                handleRemoveFilter={handleRemoveFilter}
                callback={handleCallback}

                
              />
            </Box>
            
          </Container>
        </Box>
      )}
    </div>
  );
};

export default VintedFilter;
