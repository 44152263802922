import React, { useState } from "react";
import vintxpressLogo from "../../images/vintxpressLogo.png";
import { Checkbox, FormControlLabel } from "@mui/material";

import {
  Box,
  Button,
  Typography,
  Card,
  Modal,
  CardContent,
  CardActions,
  CardHeader,
  TextField,
  InputLabel,
  Grid,
  Chip,
  Select,
  Stack,
  Alert,
  MenuItem,
  Autocomplete,
  InputAdornment,
  useMediaQuery,
  FormControl,
  useTheme,
} from "@mui/material";
import vintedService from "../../services/vinted";
const getModalStyle = (isMobile) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobile ? "100%" : "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
});

const sendTestWebhook = async (webhookURL) => {
  try {
    const response = await fetch(webhookURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        content: "El webhook de autobuy funciona correctamente! 🎉",
        username: "VintXpress Dashboard Test", // Optional: You can set a custom username for the webhook message
        avatar_url:
          "https://pbs.twimg.com/profile_images/1704078633067810816/7Y9XtVZA_400x400.jpg", // Optional: You can set a custom avatar for the webhook message
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to send test webhook");
    }
  } catch (error) {
    setError(
      "Error enviando el webhook. Revisa el URL e inténtalo de nuevo."
    );
  }
};
export const AddToken = (props) => {
  const { token, callback } = props;

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [isMain, setIsMain] = useState(false);
  const [webhook, setWebhook] = useState("");
  const [error, setError] = useState(null);
  const [missingField, setMissingField] = useState("");

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = () => {
    setError(null);
    setOpen(true); 
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAddToken = async (event) => {
    event.preventDefault();
    if (!name || !value) {
      setError("Both name and value are required");
      return;
    }

    try {
      await vintedService.addNewTokenTask({ name, value, isMain , webhook }, token);
      setName("");
      setValue("");
      setIsMain(false);
      setWebhook("");
      setError(null);
      setOpen(false);
      callback(true);
    } catch (err) {
      setError(
        err.response?.data?.message ||
          "An error occurred while creating the token"
      );
    }
  };

  return (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          display: { xs: "block", md: "flex" },
          justifyContent: "space-between",
          flexWrap: "wrap",
          m: -1,
        }}
      >
        <Typography sx={{ m: 1 }} variant="h4">
          Tokens Vinted
        </Typography>
        <Box sx={{ m: 1 }}>
          <Button color="primary" variant="contained" onClick={handleOpen}>
            Añadir Token
          </Button>
        </Box>
        <Modal open={open} onClose={handleClose}>
          <form onSubmit={handleAddToken}>
            <Card sx={getModalStyle(isMobile)}>
              <CardHeader title="Añadir Token"></CardHeader>

              {error && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "64px",
                    left: "0",
                    right: "0",
                    margin: "auto",
                    zIndex: "1",
                  }}
                >
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%" }}
                    spacing={2}
                  >
                    <Alert severity="error">{error}</Alert>
                  </Stack>
                </Box>
              )}

              <CardContent style={{ maxHeight: "67vh", overflowY: "auto" }}>
                <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  label="Alias"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  label="Token"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  label="Webhook"
                  value={webhook}
                  onChange={(e) => setWebhook(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => sendTestWebhook(webhook)}
                        >
                          Probar
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isMain}
                      onChange={(e) => setIsMain(e.target.checked)}
                    />
                  }
                  label="Establecer como Token Principal"
                />
              </CardContent>

              <CardActions
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  padding: "16px",
                  position: "sticky",
                  bottom: 0,
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={!name || !value}
                  style={{ alignSelf: "flex-start" }}
                >
                  Add Token
                </Button>
                <Button
                  color="error"
                  variant="contained"
                  onClick={handleClose}
                  style={{ alignSelf: "flex-end" }}
                >
                  Cancelar
                </Button>
              </CardActions>
            </Card>
          </form>
        </Modal>
      </Box>
    </Box>
  );
};

export default AddToken;
