import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  TextField,
  FormControlLabel,
  Checkbox,
  Alert,
  InputAdornment,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import vintedService from "../../services/vinted";
const sendTestWebhook = async (webhookURL) => {
  try {
    const response = await fetch(webhookURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        content: "El webhook de autobuy funciona correctamente! 🎉",
        username: "VintXpress Dashboard Test", // Optional: You can set a custom username for the webhook message
        avatar_url:
          "https://pbs.twimg.com/profile_images/1704078633067810816/7Y9XtVZA_400x400.jpg", // Optional: You can set a custom avatar for the webhook message
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to send test webhook");
    }
  } catch (error) {
    setError(
      "Error enviando el webhook. Revisa el URL e inténtalo de nuevo."
    );
  }
};
const getModalStyle = (isMobile) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobile ? "100%" : "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
});
const UpdateToken = ({ token , tokenData, open, handleClose, callback }) => {
  const [name, setName] = useState(tokenData.name);
  const [value, setValue] = useState(tokenData.value);
  const [isMain, setIsMain] = useState(tokenData.isMain);
  const [webhook, setWebhook] = useState(tokenData.webhook);

  const [error, setError] = useState(null);

  const handleUpdateToken = async (event) => {
    event.preventDefault();
    // Add validation and error handling as needed
    try {
      await vintedService.updateTokenTask(
        { name, value, isMain , webhook},
        tokenData.id,
        token
      );
      callback(true); // To refresh the token list
      handleClose(); // Close the modal
    } catch (err) {
      setError("Error message here"); // Handle error
    }
  };
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Modal open={open} onClose={handleClose}>
      <form onSubmit={handleUpdateToken}>
        <Card sx={getModalStyle(isMobile)}>
          <CardHeader title="Edit Token" />
          {error && <Alert severity="error">{error}</Alert>}
          <CardContent>
            <Stack spacing={2}>
              {" "}
              {/* Adjust the spacing value as needed */}
              <TextField
                fullWidth
                label="Alias"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                fullWidth
                label="Token"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
                  <TextField
                fullWidth
                label="Webhook"
                value={webhook}
                onChange={(e) => setWebhook(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => sendTestWebhook(webhook)}
                      >
                        Probar
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isMain}
                    onChange={(e) => setIsMain(e.target.checked)}
                  />
                }
                label="Seleccionar como principal"
              />
            </Stack>
          </CardContent>
          <CardActions>
            <Button type="submit" color="primary" variant="contained">
              Update Token
            </Button>
            <Button onClick={handleClose} color="error" variant="contained">
              Cancel
            </Button>
          </CardActions>
        </Card>
      </form>
    </Modal>
  );
};

export default UpdateToken;
