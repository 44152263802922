import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_API_ENDPOINT}/api/vinted`;

const getAllFiltersTask = async (token) => {
    const { data } = await axios({
        url: '/filter',
        method: 'get',
        baseURL: baseUrl,
        headers: { Authorization: `Bearer ${token}` },
    });
    //console.log(data)
    return data;
};

const addNewFilterTask = async (filterInfo, token) => {
    const { brand, minStars , maxPrice, status, webhook , categories, positiveKeywords, negativeKeywords} =
        filterInfo;
    try{

    //console.log(filterInfo)
    const  response  = await axios({
        url: '/filter',
        method: 'post',
        baseURL: baseUrl,
        data: {
            brand: brand,
            maxPrice: maxPrice,
            minStars: minStars,
            status: status,
            categories: categories,
            webhook: webhook,
            positiveKeywords: positiveKeywords,
            negativeKeywords: negativeKeywords
        },
        headers: { Authorization: `Bearer ${token}` },
    });
    return response;
    } catch (error) {
        if (error.response && error.response.data) {
            return { error: error.response.data.error };
        } else {
            return { error: 'An unexpected error occurred.' };
        }
    }

};



const updateFilterTask = async (filterId, filterInfo, token) => {
    const {  minStars , maxPrice, status, webhook , categories, positiveKeywords, negativeKeywords} =
        filterInfo;
    try {
    const response  = await axios({
        url: '/filter/' + filterId,
        method: 'post',
        baseURL: baseUrl,
        data: {
            maxPrice: maxPrice,
            minStars: minStars,
            status: status,
            webhook: webhook,
            categories : categories,
            positiveKeywords: positiveKeywords,
            negativeKeywords: negativeKeywords
        },
        headers: { Authorization: `Bearer ${token}` },
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data) {
            return { error: error.response.data.error };
        } else {
            return { error: 'An unexpected error occurred.' };
        }
    }
};


    const deleteOldFilterTask = async ( filterId , token) => {
        //console.log(token , filterId)
        const { status } = await axios({
            url: '/filter/'+ filterId,
            method: 'delete',
            baseURL: baseUrl,
            headers: { Authorization: `Bearer ${token}` },
        });
        return status;
    };
    const getBrandsTask = async ( token) => {
        //console.log(token , filterId)
        const { data } = await axios({
            url: '/brands',
            method: 'get',
            baseURL: baseUrl,
            headers: { Authorization: `Bearer ${token}` },
        });
        //console.log(data)
        return data
    };
    const getAllTokensTask = async (token) => {
        const { data } = await axios({
            url: '/token',
            method: 'get',
            baseURL: baseUrl,
            headers: { Authorization: `Bearer ${token}` },
        });
        return data;
    };
    
    const addNewTokenTask = async (tokenInfo, token) => {
        try {
            const response = await axios({
                url: '/token',
                method: 'post',
                baseURL: baseUrl,
                data: tokenInfo,
                headers: { Authorization: `Bearer ${token}` },
            });
            return response;
        } catch (error) {
            return errorHandling(error);
        }
    };
    
    const deleteOldTokenTask = async (tokenId, token) => {
        try {
            const { status } = await axios({
                url: `/token/${tokenId}`,
                method: 'delete',
                baseURL: baseUrl,
                headers: { Authorization: `Bearer ${token}` },
            });
            return status;
        } catch (error) {
            return errorHandling(error);
        }
    };
    
    const errorHandling = (error) => {
        if (error.response && error.response.data) {
            return { error: error.response.data.error };
        } else {
            return { error: 'An unexpected error occurred.' };
        }
    };
    
    const updateTokenTask = async (tokenInfo, tokenId, userToken) => {
        try {
            const response = await axios({
                url: `/token/${tokenId}`,
                method: 'put', // Ensure to use 'put' for an update operation
                baseURL: baseUrl,
                data: tokenInfo, // Your updated token data
                headers: { Authorization: `Bearer ${userToken}` },
            });
            return response;
        } catch (error) {
            return errorHandling(error);
        }
    };
    
    const vintedService = {
        getAllFiltersTask,
        addNewFilterTask,
        updateFilterTask,
        deleteOldFilterTask,
        getBrandsTask,
        getAllTokensTask,
        addNewTokenTask,
        updateTokenTask,
        deleteOldTokenTask
    };
    
    export default vintedService;
