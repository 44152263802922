import React from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  Chip,
  useMediaQuery,
  Container,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import UpdateModal from "./filter-toolbars/UpdateModal";
const statusMapping = {
  5: "Nuevo con etiquetas",
  4: "Nuevo sin etiquetas",
  3: "Usado en perfecto estado",
  2: "Usado en buen estado",
  1: "Usado en mal estado",
  0: "Cualquier condición",

};
const categoriesList = [
  { id: 5, title: "Hombre" },
  { id: 1904, title: "Mujer" },
  { id: 2050, title: "Hombre - Ropa" },
  { id: 1231, title: "Hombre - Calzado" },
  { id: 82, title: "Hombre - Accesorios" },
  { id: 139, title: "Hombre - Cuidado y belleza" },
  { id: 4, title: "Mujer - Ropa" },
  { id: 16, title: "Mujer - Calzado" },
  { id: 19, title: "Mujer - Bolsos" },
  { id: 1187, title: "Mujer - Accesorios" },
  { id: 146, title: "Mujer - Cuidado y belleza" },
  { id: 1206, title: "Hombre - Ropa - Abrigos y chaquetas" },
  { id: 76, title: "Hombre - Ropa - Camisetas y camisas" },
  { id: 32, title: "Hombre - Ropa - Trajes y blazers" },
  { id: 79, title: "Hombre - Ropa - Jerséis y sudaderas" },
  { id: 34, title: "Hombre - Ropa - Pantalones" },
  { id: 80, title: "Hombre - Ropa - Shorts" },
  { id: 85, title: "Hombre - Ropa - Pijamas" },
  { id: 84, title: "Hombre - Ropa - Bañadores" },
  { id: 30, title: "Hombre - Ropa - Ropa y accesorios deportivos" },
  { id: 92, title: "Hombre - Ropa - Disfraces y trajes especiales" },
  { id: 83, title: "Hombre - Ropa - Otras prendas" },
  { id: 257, title: "Hombre - Ropa - Vaqueros" },
  { id: 2656, title: "Hombre - Calzado - Naúticos y mocasines" },
  { id: 1233, title: "Hombre - Calzado - Botas" },
  { id: 2657, title: "Hombre - Calzado - Alpargatas" },
  { id: 2658, title: "Hombre - Calzado - Sandalias y chanclas" },
  { id: 1238, title: "Hombre - Calzado - Zapatos de vestir" },
  { id: 2659, title: "Hombre - Calzado - Pantuflas" },
  { id: 1452, title: "Hombre - Calzado - Zapatillas de deporte" },
  { id: 1242, title: "Hombre - Calzado - Zapatillas" },
  { id: 93, title: "Hombre - Accesorios - Corbatas y pañuelos" },
  { id: 94, title: "Hombre - Accesorios - Mochilas y bolsos" },
  { id: 95, title: "Hombre - Accesorios - Joyería" },
  { id: 96, title: "Hombre - Accesorios - Cinturones" },
  { id: 97, title: "Hombre - Accesorios - Relojes" },
  { id: 98, title: "Hombre - Accesorios - Gafas de sol" },
  { id: 86, title: "Hombre - Accesorios - Sombreros y gorros" },
  { id: 87, title: "Hombre - Accesorios - Bufandas y pañuelos" },
  { id: 91, title: "Hombre - Accesorios - Guantes" },
  { id: 99, title: "Hombre - Accesorios - Otros" },
  { id: 143, title: "Hombre - Cuidado y belleza - Cuidado facial" },
  {
    id: 2055,
    title: "Hombre - Cuidado y belleza - Utensilios de aseo y belleza",
  },
  { id: 140, title: "Hombre - Cuidado y belleza - Cuidado del cabello" },
  { id: 141, title: "Hombre - Cuidado y belleza - Cuidado corporal" },
  { id: 142, title: "Hombre - Cuidado y belleza - Cuidado de manos y uñas" },
  { id: 145, title: "Hombre - Cuidado y belleza - Perfumes" },
  { id: 144, title: "Hombre - Cuidado y belleza - Maquillaje" },
  { id: 1863, title: "Hombre - Cuidado y belleza - Sets" },
  { id: 968, title: "Hombre - Cuidado y belleza - Otros" },
  { id: 1037, title: "Mujer - Ropa - Abrigos y cazadoras" },
  { id: 13, title: "Mujer - Ropa - Jerséis y sudaderas" },
  { id: 8, title: "Mujer - Ropa - Trajes y blazers" },
  { id: 10, title: "Mujer - Ropa - Vestidos" },
  { id: 11, title: "Mujer - Ropa - Faldas" },
  { id: 12, title: "Mujer - Ropa - Camisetas y tops" },
  { id: 183, title: "Mujer - Ropa - Vaqueros" },
  { id: 9, title: "Mujer - Ropa - Pantalones y leggings" },
  { id: 15, title: "Mujer - Ropa - Shorts" },
  { id: 1035, title: "Mujer - Ropa - Monos" },
  { id: 28, title: "Mujer - Ropa - Ropa de baño" },
  { id: 29, title: "Mujer - Ropa - Lencería y pijamas" },
  { id: 1176, title: "Mujer - Ropa - Premamá" },
  { id: 73, title: "Mujer - Ropa - Ropa deportiva" },
  { id: 1782, title: "Mujer - Ropa - Disfraces y trajes especiales" },
  { id: 18, title: "Mujer - Ropa - Otras prendas" },
  { id: 1049, title: "Mujer - Calzado - Botas" },
  { id: 2623, title: "Mujer - Calzado - Zuecos y mules" },
  { id: 1909, title: "Mujer - Calzado - Zapatos planos" },
  { id: 2624, title: "Mujer - Calzado - Sandalias y chanclas" },
  { id: 543, title: "Mujer - Calzado - Tacones" },
  { id: 215, title: "Mujer - Calzado - Pantuflas" },
  { id: 2630, title: "Mujer - Calzado - Zapatillas de deporte" },
  { id: 2632, title: "Mujer - Calzado - Zapatillas" },
  { id: 156, title: "Mujer - Bolsos - Bolsos de mano" },
  { id: 158, title: "Mujer - Bolsos - Bolsos de hombro" },
  { id: 157, title: "Mujer - Bolsos - Mochilas" },
  { id: 552, title: "Mujer - Bolsos - Bolsos tote" },
  { id: 159, title: "Mujer - Bolsos - Bolsos de fiesta" },
  { id: 160, title: "Mujer - Bolsos - Monederos y carteras" },
  { id: 161, title: "Mujer - Bolsos - Neceseres" },
  { id: 1784, title: "Mujer - Bolsos - Satchels" },
  { id: 1848, title: "Mujer - Bolsos - Riñoneras" },
  { id: 1493, title: "Mujer - Bolsos - Bolsos estampados o bordados" },
  { id: 1849, title: "Mujer - Bolsos - Bolsas de deporte" },
  { id: 1850, title: "Mujer - Bolsos - Maletas" },
  { id: 155, title: "Mujer - Bolsos - Otros" },
  { id: 21, title: "Mujer - Accesorios - Joyería" },
  { id: 22, title: "Mujer - Accesorios - Relojes" },
  { id: 20, title: "Mujer - Accesorios - Cinturones" },
  { id: 26, title: "Mujer - Accesorios - Gafas de sol" },
  { id: 89, title: "Mujer - Accesorios - Bufandas y pañuelos" },
  { id: 90, title: "Mujer - Accesorios - Guantes" },
  { id: 88, title: "Mujer - Accesorios - Sombreros y gorros" },
  { id: 1123, title: "Mujer - Accesorios - Accesorios de cabello" },
  { id: 1221, title: "Mujer - Accesorios - Accesorios de tecnología" },
  { id: 1851, title: "Mujer - Accesorios - Paraguas" },
  { id: 1852, title: "Mujer - Accesorios - Llaveros" },
  { id: 1140, title: "Mujer - Accesorios - Otros" },
  { id: 964, title: "Mujer - Cuidado y belleza - Maquillaje" },
  { id: 152, title: "Mujer - Cuidado y belleza - Perfume" },
  { id: 948, title: "Mujer - Cuidado y belleza - Cuidado facial" },
  { id: 1906, title: "Mujer - Cuidado y belleza - Accesorios de belleza" },
  { id: 1264, title: "Mujer - Cuidado y belleza - Cuidado de las manos" },
  { id: 960, title: "Mujer - Cuidado y belleza - Manicura" },
  { id: 956, title: "Mujer - Cuidado y belleza - Cuidado corporal" },
  { id: 1902, title: "Mujer - Cuidado y belleza - Cuidado del cabello" },
  { id: 153, title: "Mujer - Cuidado y belleza - Otros productos de belleza" },
];
function getCategoryTitleById(id) {
  const category = categoriesList.find((category) => category.id.toString() === id.toString());
  if (!category) {
    console.log(`Category not found for id: ${id}`);
  }
  return category ? category.title : "Unknown";
}
const FiltersList = ({ filters, fields, handleRemoveFilter, callback}) => {
  const [isUpdateModalOpen, setUpdateModalOpen] = React.useState(false);
  const [currentFilter, setCurrentFilter] = React.useState(null);
  //console.log(callback)
  const handleRowClick = (filter) => {
    setCurrentFilter(filter);
    setUpdateModalOpen(true);
  };


  // Check if it's a mobile viewport.
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  if (isMobile) {
    return (
      <Box>
        {filters.map((filter) => (
          <Chip
            label={
              filter.maxPrice === 0
                ? filter.brand
                : `${filter.brand} - ${filter.maxPrice} €`
            }
            onClick={() => handleRowClick(filter)}
            onDelete={() => handleRemoveFilter(filter.id)}
            variant="outlined"
            color="primary"
            style={{
              margin: "5px",
              fontSize: "1rem", // Bigger font size
              padding: "10px 15px", // Bigger padding
              borderRadius: "8px", // Less curve
              minWidth: "350px", // Minimum width
              maxWidth: "350px", // Maximum width
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          />
        ))}
        {isUpdateModalOpen && (
          <Container maxWidth="lg">
            <UpdateModal
              filter={currentFilter}
              open={isUpdateModalOpen}
              onClose={() => {
                setUpdateModalOpen(false);
                setCurrentFilter(null);
              }}
              callback={callback}

            />
          </Container>
        )}
      </Box>
    );
  }

  // Desktop view
  return (
    <Card>
      <Box>
        <Table sx={{ overflowX: "auto" }}>
          <TableHead>
            <TableRow>
              {fields.map((item) => (
                <TableCell key={item}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filters.map((filter) => (
              <TableRow
                hover
                key={filter.id}
                onClick={() => handleRowClick(filter)}
              >
                <TableCell>{filter.brand}</TableCell>
                <TableCell style={{ width: "150px" }}>
                  {filter.maxPrice === 0
                    ? ""
                    : filter.maxPrice <= 1000
                    ? `${filter.maxPrice} €`
                    : `${filter.maxPrice}\n€`}
                </TableCell>
                <TableCell>
                  {filter.minStars === 0
                    ? ""
                    : filter.minStars && `${filter.minStars} ⭐`}
                </TableCell>
                <TableCell>
                  {statusMapping[filter.status] || "Unknown"}
                </TableCell>
                {/* Add a new TableCell for Categories */}
                <TableCell>
                  {filter.categories.map((categoryId) => (
                    <Grid item key={`${filter.id}-category-${categoryId}`}>
                      <Chip
                        label={getCategoryTitleById(categoryId)}
                        color="primary"
                        variant="outlined"
                        size="small"
                        style={{ borderRadius: "20%" }}
                      />
                    </Grid>
                  ))}
                </TableCell>
                <TableCell>
                  {filter.positiveKeywords.map((keyword) => (
                    <Grid item key={`${filter.id}-positive-${keyword}`}>
                      <Chip
                        label={keyword}
                        color="success"
                        variant="outlined"
                        size="small"
                        style={{ borderRadius: "20%" }}
                      />
                    </Grid>
                  ))}
                </TableCell>
                <TableCell>
                  {filter.negativeKeywords.map((keyword) => (
                    <Grid item key={`${filter.id}-negative-${keyword}`}>
                      <Chip
                        label={keyword}
                        color="error"
                        variant="outlined"
                        size="small"
                        style={{ borderRadius: "20%" }}
                      />
                    </Grid>
                  ))}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation(); // This will prevent the event from bubbling up to the TableRow
                      handleRemoveFilter(filter.id);
                    }}
                    color="error"
                  >
                    <ClearIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {isUpdateModalOpen && (
          <Container maxWidth="lg">
            <UpdateModal
              filter={currentFilter}
              open={isUpdateModalOpen}
              onClose={() => {
                setUpdateModalOpen(false);
                setCurrentFilter(null);
              }}
              callback={callback}
            />
          </Container>
        )}
      </Box>
    </Card>
  );
};

export default FiltersList;