// src/components/Counter.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const Counter = ({ count, label }) => {
    return (
        <Box sx={{
            background: 'linear-gradient(45deg, #111827 30%, #00bbc7 90%)',
            borderRadius: '8px',
            padding: '8px',  // Reduced padding
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#FFFFFF',
        }}>
            <Typography variant="h5">  {/* Adjusted variant */}
                {label}
            </Typography>
            <Typography variant="h6" gutterBottom>  {/* Adjusted variant */}
                {count.toLocaleString()}
            </Typography>

        </Box>
    );
};

export default Counter;
