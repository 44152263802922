import { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Divider, Drawer, useMediaQuery } from "@mui/material";
import { NavItem } from "./nav-item";
import { useLocation, Link } from "react-router-dom";
import BarChartIcon from "@mui/icons-material/BarChart";
import { WhopIcon } from "../../icons/whop";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import React from "react";
import vintxpressLogo from "../../images/vintxpressLogo.png";
import StarIcon from "@mui/icons-material/Star";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RepeatIcon from "@mui/icons-material/Repeat";
import TokenIcon from '@mui/icons-material/Token';
import { FaTiktok, FaSquareXTwitter } from "react-icons/fa6";

const general = [
  {
    href: "/",
    icon: <BarChartIcon />,
    title: "Inicio",
  },
];

const features = [
  {
    href: "/vinted/filters",
    icon: <NotificationAddIcon />,
    title: "Filtros",
  },
  {
    href: "/vinted/tokens",
    icon: <TokenIcon />,
    title: "Autobuy",
  },

];
const upcomingFeatures = [
  {
    href: "#", // Placeholder
    icon: <RepeatIcon />,
    title: "Bot Repost",
  },

  {
    href: "#", // Placeholder since you mentioned no link
    icon: <VisibilityIcon />,
    title: "Bot visitas",
  },
  {
    href: "#", // Placeholder
    icon: <StarIcon />,
    title: "Bot favoritos",
  },

];
const IconWrapper = ({ children }) => (
  <div
    style={{
      width: 18,
      height: 18,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    {children}
  </div>
);

// ...
const whop = [
  {
    href: "https://whop.com/vintxpress/",
    icon: (
      <IconWrapper>
        <WhopIcon />
      </IconWrapper>
    ),
    title: " Whop",
  },
];

export const DashboardSidebar = (props) => {
  const { open, onClose } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });
  const location = useLocation();

  useEffect(() => {
    if (open) {
      onClose?.();
    }
  }, [location.pathname]);

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div>
          <Box sx={{ p: 3 }}>
            <Link to="/">
              <img
                src={vintxpressLogo}
                alt="VintXpress Logo"
                style={{
                  height: 80,
                  width: 80,
                }}
              />
            </Link>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: "#2D3748",
            my: 1.5,
          }}
        />
        <Box sx={{ flexGrow: 0 }}>
          {general.map((item) => (
            <NavItem
              key={item.title}
              icon={item.icon}
              href={item.href}
              title={item.title}
            />
          ))}
        </Box>
        <Divider
          sx={{
            borderColor: "#2D3748",
            my: 3,
          }}
        />
        <Box sx={{ flexGrow: 0 }}>
          {features.map((item) => (
            <>
            <NavItem
              key={item.title}
              icon={item.icon}
              href={item.href}
              title={item.title}
            />
            <Divider
              sx={{
                borderColor: "#2D3748",
                my: 3,
              }}
            />
          </>
          ))}
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          {whop.map((item) => (
            <NavItem
              key={item.title}
              icon={item.icon}
              href={item.href}
              title={item.title}
            />
          ))}
        </Box>

        <Divider
          sx={{
            borderColor: "#2D3748",
            my: 4, // Increased margin for a bigger divider
          }}
        >
          <span style={{ marginLeft: "10px", marginRight: "10px" }}>Soon 🔒</span>
        </Divider>

        <Box sx={{ flexGrow: 0 }}>
          {upcomingFeatures.map((item) => (
            <>
              <NavItem
                key={item.title}
                icon={item.icon}
                href={item.href}
                title={item.title}
              />
              <Divider
                sx={{
                  borderColor: "#2D3748",
                  my: 3,
                }}
              />
            </>
          ))}
        </Box>
        <Box
        sx={{
          mt: "auto",
          display: "flex",
          flexDirection: "row", // Icons side by side
          alignItems: "center",
          p: 2,
        }}
      >
        <Box sx={{ marginRight: 2 }}>
          {" "}
          {/* Adjust this value to control spacing */}
          <Link
            to="https://twitter.com/VintXpress"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaSquareXTwitter
              color="grey"
              size={32}
              sx={{ cursor: "pointer", marginRight: 40 }}
            />
          </Link>
        </Box>

        <Box>
          <Link
            to="https://www.tiktok.com/@vintxpress"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTiktok
              color="grey"
              size={30}
              sx={{ cursor: "pointer", marginRight: 60 }}
            />
          </Link>
        </Box>
      </Box>
      </Box>

    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.900",
            color: "#FFFFFF",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          color: "#FFFFFF",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
