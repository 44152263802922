import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  LinearProgress,
  Stack,
  Alert,
} from "@mui/material";
import { AddToken } from "../../components/token-toolbars/addToken";
import TokensList from "../../components/tokens-list";
import vintedService from "../../services/vinted";
import { useAuth } from "../../hooks/useAuth";
import { Token } from "@mui/icons-material";

const fields = [
  "name",
  "value",
  "min stars",
];

const VintedToken = () => {
  const [tokens, setTokens] = useState([]);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const auth = useAuth();

  const handleCallback = (state) => {
    if (state) {
      vintedService.getAllTokensTask(auth.token)
        .then((response) => {
          setTokens(response);
        })
        .catch(error => {
          console.error("Failed to fetch tokens:", error);
        });
    }
  };

  const handleRemoveToken = async (tokenId) => {
    const response = await vintedService.deleteOldTokenTask(tokenId, auth.token);
    if (response === 204) {
      const updatedTokens = tokens.filter((token) => token.id !== tokenId);
      setTokens(updatedTokens);
      // Trigger an update to re-fetch tokens
      setUpdate(prev => !prev);
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 1500);
    }
  };
  
  useEffect(() => {
    setLoading(true);
    vintedService.getAllTokensTask(auth.token).then((response) => {
      setTokens(response);
      setLoading(false);
    });
}, [update, auth.token]);

  return (
    <div className="scrollable-container">
      {loading ? (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </Box>
      ) : (
<Box
  component="main"
  sx={{
    flexGrow: 1,
    py: 8,
    position: "relative",
    overflowX: "auto", // Enable horizontal scrolling
    maxWidth: "100%", // Ensure the content can be wider than the viewport
  }}
>
          {alert && (
            <Box
              sx={{
                position: "absolute",
                top: "64px",
                left: "0",
                right: "0",
                margin: "auto",
                zIndex: "1",
              }}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%" }}
                spacing={2}
              >
                <Alert severity="success">Successfully removed Token!</Alert>
              </Stack>
            </Box>
          )}
          <Container maxWidth="lg">
            <AddToken
              token={auth.token}
              callback={handleCallback}
            />
            <Box sx={{ mt: 3 }}>
              <TokensList
                token={auth.token}
                tokens={tokens}
                fields={fields}
                handleRemoveToken={handleRemoveToken}
                callback={handleCallback}

                
              />
            </Box>
          </Container>
        </Box>
      )}
    </div>
  );
};

export default VintedToken;
