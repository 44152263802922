import React, { useState } from "react";
import vintxpressLogo from "../../images/vintxpressLogo.png";

import {
  Box,
  Button,
  Typography,
  Card,
  Modal,
  CardContent,
  CardActions,
  CardHeader,
  TextField,
  InputLabel,
  Grid,
  Chip,
  Select,
  Stack,
  Alert,
  MenuItem,
  Autocomplete,
  InputAdornment,
  useMediaQuery,
  FormControl,
  useTheme,
} from "@mui/material";
import vintedService from "../../services/vinted";
const getModalStyle = (isMobile) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobile ? "100%" : "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
});

const statusMapping = {
  5: "Nuevo con etiquetas",
  4: "Nuevo sin etiquetas",
  3: "Usado en perfecto estado",
  2: "Usado en buen estado",
  1: "Usado en mal estado",
  0: "Cualquier condición",
};

export const AddFilter = (props) => {


  const categoriesList = [
    { id: 5, title: "Hombre" },
    { id: 1904, title: "Mujer" },
    { id: 2050, title: "Hombre - Ropa" },
    { id: 1231, title: "Hombre - Calzado" },
    { id: 82, title: "Hombre - Accesorios" },
    { id: 139, title: "Hombre - Cuidado y belleza" },
    { id: 4, title: "Mujer - Ropa" },
    { id: 16, title: "Mujer - Calzado" },
    { id: 19, title: "Mujer - Bolsos" },
    { id: 1187, title: "Mujer - Accesorios" },
    { id: 146, title: "Mujer - Cuidado y belleza" },
    { id: 1206, title: "Hombre - Ropa - Abrigos y chaquetas" },
    { id: 76, title: "Hombre - Ropa - Camisetas y camisas" },
    { id: 32, title: "Hombre - Ropa - Trajes y blazers" },
    { id: 79, title: "Hombre - Ropa - Jerséis y sudaderas" },
    { id: 34, title: "Hombre - Ropa - Pantalones" },
    { id: 80, title: "Hombre - Ropa - Shorts" },
    { id: 85, title: "Hombre - Ropa - Pijamas" },
    { id: 84, title: "Hombre - Ropa - Bañadores" },
    { id: 30, title: "Hombre - Ropa - Ropa y accesorios deportivos" },
    { id: 92, title: "Hombre - Ropa - Disfraces y trajes especiales" },
    { id: 83, title: "Hombre - Ropa - Otras prendas" },
    { id: 257, title: "Hombre - Ropa - Vaqueros" },
    { id: 2656, title: "Hombre - Calzado - Naúticos y mocasines" },
    { id: 1233, title: "Hombre - Calzado - Botas" },
    { id: 2657, title: "Hombre - Calzado - Alpargatas" },
    { id: 2658, title: "Hombre - Calzado - Sandalias y chanclas" },
    { id: 1238, title: "Hombre - Calzado - Zapatos de vestir" },
    { id: 2659, title: "Hombre - Calzado - Pantuflas" },
    { id: 1452, title: "Hombre - Calzado - Zapatillas de deporte" },
    { id: 1242, title: "Hombre - Calzado - Zapatillas" },
    { id: 93, title: "Hombre - Accesorios - Corbatas y pañuelos" },
    { id: 94, title: "Hombre - Accesorios - Mochilas y bolsos" },
    { id: 95, title: "Hombre - Accesorios - Joyería" },
    { id: 96, title: "Hombre - Accesorios - Cinturones" },
    { id: 97, title: "Hombre - Accesorios - Relojes" },
    { id: 98, title: "Hombre - Accesorios - Gafas de sol" },
    { id: 86, title: "Hombre - Accesorios - Sombreros y gorros" },
    { id: 87, title: "Hombre - Accesorios - Bufandas y pañuelos" },
    { id: 91, title: "Hombre - Accesorios - Guantes" },
    { id: 99, title: "Hombre - Accesorios - Otros" },
    { id: 143, title: "Hombre - Cuidado y belleza - Cuidado facial" },
    {
      id: 2055,
      title: "Hombre - Cuidado y belleza - Utensilios de aseo y belleza",
    },
    { id: 140, title: "Hombre - Cuidado y belleza - Cuidado del cabello" },
    { id: 141, title: "Hombre - Cuidado y belleza - Cuidado corporal" },
    { id: 142, title: "Hombre - Cuidado y belleza - Cuidado de manos y uñas" },
    { id: 145, title: "Hombre - Cuidado y belleza - Perfumes" },
    { id: 144, title: "Hombre - Cuidado y belleza - Maquillaje" },
    { id: 1863, title: "Hombre - Cuidado y belleza - Sets" },
    { id: 968, title: "Hombre - Cuidado y belleza - Otros" },
    { id: 1037, title: "Mujer - Ropa - Abrigos y cazadoras" },
    { id: 13, title: "Mujer - Ropa - Jerséis y sudaderas" },
    { id: 8, title: "Mujer - Ropa - Trajes y blazers" },
    { id: 10, title: "Mujer - Ropa - Vestidos" },
    { id: 11, title: "Mujer - Ropa - Faldas" },
    { id: 12, title: "Mujer - Ropa - Camisetas y tops" },
    { id: 183, title: "Mujer - Ropa - Vaqueros" },
    { id: 9, title: "Mujer - Ropa - Pantalones y leggings" },
    { id: 15, title: "Mujer - Ropa - Shorts" },
    { id: 1035, title: "Mujer - Ropa - Monos" },
    { id: 28, title: "Mujer - Ropa - Ropa de baño" },
    { id: 29, title: "Mujer - Ropa - Lencería y pijamas" },
    { id: 1176, title: "Mujer - Ropa - Premamá" },
    { id: 73, title: "Mujer - Ropa - Ropa deportiva" },
    { id: 1782, title: "Mujer - Ropa - Disfraces y trajes especiales" },
    { id: 18, title: "Mujer - Ropa - Otras prendas" },
    { id: 1049, title: "Mujer - Calzado - Botas" },
    { id: 2623, title: "Mujer - Calzado - Zuecos y mules" },
    { id: 1909, title: "Mujer - Calzado - Zapatos planos" },
    { id: 2624, title: "Mujer - Calzado - Sandalias y chanclas" },
    { id: 543, title: "Mujer - Calzado - Tacones" },
    { id: 215, title: "Mujer - Calzado - Pantuflas" },
    { id: 2630, title: "Mujer - Calzado - Zapatillas de deporte" },
    { id: 2632, title: "Mujer - Calzado - Zapatillas" },
    { id: 156, title: "Mujer - Bolsos - Bolsos de mano" },
    { id: 158, title: "Mujer - Bolsos - Bolsos de hombro" },
    { id: 157, title: "Mujer - Bolsos - Mochilas" },
    { id: 552, title: "Mujer - Bolsos - Bolsos tote" },
    { id: 159, title: "Mujer - Bolsos - Bolsos de fiesta" },
    { id: 160, title: "Mujer - Bolsos - Monederos y carteras" },
    { id: 161, title: "Mujer - Bolsos - Neceseres" },
    { id: 1784, title: "Mujer - Bolsos - Satchels" },
    { id: 1848, title: "Mujer - Bolsos - Riñoneras" },
    { id: 1493, title: "Mujer - Bolsos - Bolsos estampados o bordados" },
    { id: 1849, title: "Mujer - Bolsos - Bolsas de deporte" },
    { id: 1850, title: "Mujer - Bolsos - Maletas" },
    { id: 155, title: "Mujer - Bolsos - Otros" },
    { id: 21, title: "Mujer - Accesorios - Joyería" },
    { id: 22, title: "Mujer - Accesorios - Relojes" },
    { id: 20, title: "Mujer - Accesorios - Cinturones" },
    { id: 26, title: "Mujer - Accesorios - Gafas de sol" },
    { id: 89, title: "Mujer - Accesorios - Bufandas y pañuelos" },
    { id: 90, title: "Mujer - Accesorios - Guantes" },
    { id: 88, title: "Mujer - Accesorios - Sombreros y gorros" },
    { id: 1123, title: "Mujer - Accesorios - Accesorios de cabello" },
    { id: 1221, title: "Mujer - Accesorios - Accesorios de tecnología" },
    { id: 1851, title: "Mujer - Accesorios - Paraguas" },
    { id: 1852, title: "Mujer - Accesorios - Llaveros" },
    { id: 1140, title: "Mujer - Accesorios - Otros" },
    { id: 964, title: "Mujer - Cuidado y belleza - Maquillaje" },
    { id: 152, title: "Mujer - Cuidado y belleza - Perfume" },
    { id: 948, title: "Mujer - Cuidado y belleza - Cuidado facial" },
    { id: 1906, title: "Mujer - Cuidado y belleza - Accesorios de belleza" },
    { id: 1264, title: "Mujer - Cuidado y belleza - Cuidado de las manos" },
    { id: 960, title: "Mujer - Cuidado y belleza - Manicura" },
    { id: 956, title: "Mujer - Cuidado y belleza - Cuidado corporal" },
    { id: 1902, title: "Mujer - Cuidado y belleza - Cuidado del cabello" },
    {
      id: 153,
      title: "Mujer - Cuidado y belleza - Otros productos de belleza",
    },
  ];

  const sendTestWebhook = async (webhookURL) => {
    try {
      const response = await fetch(webhookURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content: "El webhook funciona correctamente! 🎉",
          username: "VintXpress Dashboard Test", // Optional: You can set a custom username for the webhook message
          avatar_url:
            "https://pbs.twimg.com/profile_images/1704078633067810816/7Y9XtVZA_400x400.jpg", // Optional: You can set a custom avatar for the webhook message
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send test webhook");
      }
    } catch (error) {
      setError(
        "Error enviando el webhook. Revisa el URL e inténtalo de nuevo."
      );
    }
  };

  const { token, callback , brandsList} = props;

  const [open, setOpen] = useState(false);
  const [maxPrice, setMaxPrice] = useState(null);
  const [brand, setBrand] = useState(null);
  const [status, setStatus] = useState(0);
  const [minStars, setMinStars] = useState(null);
  const [newPositiveKeyword, setNewPositiveKeyword] = useState(""); // Track the new positive keyword
  const [newNegativeKeyword, setNewNegativeKeyword] = useState(""); // Track the new negative keyword
  const [positiveKeywords, setPositiveKeywords] = useState([]); // Initialize as an empty array
  const [negativeKeywords, setNegativeKeywords] = useState([]); // Initialize as an empty array
  const [webhook, setWebhook] = useState("");
  const [error, setError] = useState(null);
  const [missingField, setMissingField] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);

  const theme = useTheme();

  // Check if it's a mobile viewport.
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Function to handle the update of an existing filter

  const handleOpen = () => {
    setError(null); // Clear any previous errors
    setOpen(true);
  };

  const handleClose = () => {
    setError(null);
    resetForm(); // Clear any previous errors when closing
    setOpen(false);
  };

  const resetForm = () => {
    setMaxPrice(null);
    setBrand(null);
    setStatus(0);
    setMinStars(null);
    setPositiveKeywords([]);
    setNegativeKeywords([]);
    setWebhook("");
    setSelectedCategories([]);
    setError(null);
    setMissingField("");
    // ... any other states that need resetting
  };
  const handleAddFilter = async (event) => {
    // Check if webhook is missing
    event.preventDefault(); // Prevents the form from submitting in the traditional way

    if (!webhook) {
      setMissingField("webhook");
      return;
    }

    // Check if brand is missing
    if (!brand) {
      setMissingField("brand");
      return;
    }
    let finalMaxPrice = maxPrice !== null ? maxPrice : 0;
    let finalMinStars = minStars !== null ? minStars : 0;
    let finalStatusClothing = status !== null ? status : 0;
    const categoryIds = selectedCategories.map((cat) => cat.id); // Extract only the 'id' properties

    const response = await vintedService.addNewFilterTask(
      {
        brand,
        maxPrice: finalMaxPrice,
        minStars: finalMinStars,
        status: finalStatusClothing,
        positiveKeywords,
        negativeKeywords,
        categories: categoryIds, // Send only the values of the selected categories
        webhook,
      },
      token
    );

    if (response.status === 201) {
      setMaxPrice(null);
      setBrand(null);
      setSelectedCategories([]);
      setStatus(0);
      setMinStars(null);
      setPositiveKeywords([]);
      setNegativeKeywords([]);
      setWebhook(null);
      callback(true);
      handleClose();
    } else if (response.error) {
      setError(response.error);
      setTimeout(() => {
        setError(null);
      }, 1500);
    } else {
      setError("Error al añadir el filtro. Inténtalo de nuevo.");
      setTimeout(() => {
        setError(null);
      }, 1500);
    }
  };
  const handleAddPositiveKeyword = () => {
    if (newPositiveKeyword.trim() !== "") {
      setPositiveKeywords([...positiveKeywords, newPositiveKeyword]);
      setNewPositiveKeyword(""); // Clear the text field
    }
  };

  const handleDeletePositiveKeyword = (index) => {
    const updatedKeywords = [...positiveKeywords];
    updatedKeywords.splice(index, 1);
    setPositiveKeywords(updatedKeywords);
  };

  const handleAddNegativeKeyword = () => {
    if (newNegativeKeyword.trim() !== "") {
      setNegativeKeywords([...negativeKeywords, newNegativeKeyword]);
      setNewNegativeKeyword(""); // Clear the text field
    }
  };

  const handleDeleteNegativeKeyword = (index) => {
    const updatedKeywords = [...negativeKeywords];
    updatedKeywords.splice(index, 1);
    setNegativeKeywords(updatedKeywords);
  };

  return (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          display: { xs: "block", md: "flex" },
          justifyContent: "space-between",
          flexWrap: "wrap",
          m: -1,
        }}
      >

        <Typography sx={{ m: 1 }} variant="h4">
          Filtros Personalizados
        </Typography>
        <Box sx={{ m: 1 }}>
          <Button color="primary" variant="contained" onClick={handleOpen}>
            Añadir Filtro
          </Button>
        </Box>

        <Modal open={open} onClose={handleClose}>

          <form onSubmit={handleAddFilter}>

            <Card sx={getModalStyle(isMobile)}>

              <CardHeader title="Añadir filtro"></CardHeader>
              {error && (
            <Box
              sx={{
                position: "absolute",
                top: "64px",
                left: "0",
                right: "0",
                margin: "auto",
                zIndex: "1",
              }}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%" }}
                spacing={2}
              >
                <Alert severity="error">{error}</Alert>
              </Stack>
            </Box>
          )}
              <CardContent style={{ maxHeight: "67vh", overflowY: "auto" }}>

                <Autocomplete
                  sx={{ marginBottom: 2 }} // Add margin-bottom for spacing
                  fullWidth
                  margin="normal"
                  options={brandsList}
                  value={brand}
                  onChange={(event, newValue) => setBrand(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Marca"
                      placeholder="Selecciona una marca"
                    />
                  )}
                />
                <Autocomplete
                  multiple
                  fullWidth
                  margin="normal"
                  options={categoriesList}
                  value={selectedCategories}
                  onChange={(event, newValues) =>
                    setSelectedCategories(newValues)
                  }
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Categorías"
                      placeholder="Selecciona categorías"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.title === value.title
                  }
                />

                {/* Mostrar categorías seleccionadas */}

                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel htmlFor="status-label">Estado</InputLabel>
                  <Select
                    labelId="status-label"
                    fullWidth
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    label="Estado"
                  >
                    {Object.entries(statusMapping).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  name="maxPrice"
                  label="Precio Máximo (€)"
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                  }}
                  onChange={(e) => setMaxPrice(e.target.value)}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  name="minStars"
                  label="Estrellas mínimas del vendedor"
                  type="number"
                  inputProps={{
                    step: 0.5,
                    min: 0,
                    max: 5,
                  }}
                  onChange={(e) => setMinStars(e.target.value)}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  label="Keywords Positivas"
                  value={newPositiveKeyword}
                  onChange={(e) => setNewPositiveKeyword(e.target.value)} // Track the new keyword
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={handleAddPositiveKeyword}
                        >
                          Añadir
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />

                {/* Display positive keywords */}
                <Grid container spacing={1}>
                  {positiveKeywords.map((keyword, index) => (
                    <Grid item key={index}>
                      <Chip
                        label={keyword}
                        onDelete={() => handleDeletePositiveKeyword(index)}
                        color="success" // Set color to green for positive keywords
                        variant="outlined"
                      />
                    </Grid>
                  ))}
                </Grid>

                {/* Text input for negative keywords */}
                <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  label="Keywords Negativas"
                  value={newNegativeKeyword}
                  onChange={(e) => setNewNegativeKeyword(e.target.value)} // Track the new keyword
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={handleAddNegativeKeyword}
                        >
                          Añadir
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />

                {/* Display negative keywords */}
                <Grid container spacing={1}>
                  {negativeKeywords.map((keyword, index) => (
                    <Grid item key={index}>
                      <Chip
                        label={keyword}
                        onDelete={() => handleDeleteNegativeKeyword(index)}
                        color="error" // Set color to red for negative keywords
                        variant="outlined"
                      />
                    </Grid>
                  ))}
                </Grid>

                <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  name="webhook"
                  label="Webhook"
                  required
                  onChange={(e) => setWebhook(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => sendTestWebhook(webhook)}
                        >
                          Probar
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </CardContent>
              <CardActions
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  padding: "16px",
                  position: "sticky", // Keep the buttons at the bottom
                  bottom: 0,
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={!webhook || !brand}
                  style={{ alignSelf: "flex-start" }} // Align to the start
                >
                  Add Filter
                </Button>
                <Button
                  color="error"
                  variant="contained"
                  onClick={handleClose}
                  style={{ alignSelf: "flex-end" }} // Align to the end
                >
                  Cancelar
                </Button>
              </CardActions>
            </Card>
          </form>
        </Modal>
      </Box>
    </Box>
  );
};

export default AddFilter;
