import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  Link,
  IconButton,
} from "@mui/material";
import { Navigate } from "react-router-dom";
import { useAuth, RequireNoAuth } from "../hooks/useAuth";
import { FaTiktok, FaSquareXTwitter } from "react-icons/fa6";
import { WhopIcon } from "../icons/whop";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  let auth = useAuth();

  if (auth.user && auth.token) {
    return <Navigate to="/" replace />;
  }
  const handleWhopLogin = () => {
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_REDIRECT_URI;
    const loginUrl = `http://whop.com/oauth?client_id=${clientId}&redirect_uri=${redirectUri}`;
    window.location.href = loginUrl;
  };

  return (
    <Box style={{ height: "100vh", backgroundColor: "#030418" }}>
      <RequireNoAuth>
        <Container
          maxWidth="sm"
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex={1}
          >
            <Container maxWidth="sm">
              <form>
                <Box
                  sx={{
                    py: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {/* Image above the button */}
                  <img
                    src="/vintedxpress.png"
                    alt="VintXpress Logo"
                    style={{ width: "100px", marginBottom: "20px" }}
                  />
                  <Button
                    color="secondary"
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={handleWhopLogin}
                    disabled={isLoading}
                  >
                    {isLoading ? "Logging In..." : "Log In with Whop"}
                  </Button>
                </Box>
              </form>
            </Container>
          </Box>
          <Box display="flex" justifyContent="center" mb={0}>
            <Link
              href="https://x.com/vintxpress"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                color="white"
                aria-label="Twitter"
                component="span"
                style={{ marginRight: "10px" }}
              >
                <FaSquareXTwitter />
              </IconButton>
            </Link>
            <Link
              href="https://www.tiktok.com/@vintxpress"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                color="white"
                aria-label="TikTok"
                component="span"
                style={{ margin: "0 10px" }}
              >
                <FaTiktok />
              </IconButton>
            </Link>
            <Link
              href="https://whop.com/vintxpress"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                color="white"
                aria-label="Whop"
                component="span"
                style={{ marginLeft: "10px" }}
              >
                <WhopIcon />
              </IconButton>
            </Link>
          </Box>

          {/* Footer */}
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ mt: 0, mb: 4 }}
          >
            {"Copyright © "}
            <Link color="inherit" href="#">
              VintXpress
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Container>
      </RequireNoAuth>
    </Box>
  );
};

export default Login;
