import axios from 'axios';

// Assuming the buy-item endpoint is part of the same API as the dashboard
const baseUrl = `https://autobuy-vinted.onrender.com`;

const buyItem = async ({ itemId, sellerId, userToken, webhook, authToken }) => {
    try {
        const response = await axios({
            url: 'api/autobuy/',
            method: 'post',
            baseURL: baseUrl,
            headers: { Authorization: `Bearer ${authToken}` },
            data: {
                itemId: parseInt(itemId),
                sellerId: parseInt(sellerId),
                user_token: userToken,
                webhook: webhook,
            },
        });
        return response.data;
    } catch (error) {
        // Depending on your error handling strategy, you might want to throw the error,
        // return a default error object, or handle it in some other way
        console.error('Error comprando el item:', error);
        throw error; // Re-throw the error if you want to handle it in the calling component
    }
};

const autobuyService = {
    buyItem,
    // Add other service functions here as needed
};

export default autobuyService;
