import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  InputAdornment,
  Chip,
  Select,
  MenuItem,
  InputLabel,
  Modal,
  CardActions,
  Box,
  Stack,
  Alert,
  Autocomplete,
  FormControl,
  Typography,
} from "@mui/material";
import { useAuth } from "../../hooks/useAuth";

import { Card, CardContent, CardHeader } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import vintedService from "../../services/vinted";

const statusMapping = {
  5: "Nuevo con etiquetas",
  4: "Nuevo sin etiquetas",
  3: "Usado en perfecto estado",
  2: "Usado en buen estado",
  1: "Usado en mal estado",
  0: "Cualquier condición",
};
const categoriesList = [
  { id: 5, title: "Hombre" },
  { id: 1904, title: "Mujer" },
  { id: 2050, title: "Hombre - Ropa" },
  { id: 1231, title: "Hombre - Calzado" },
  { id: 82, title: "Hombre - Accesorios" },
  { id: 139, title: "Hombre - Cuidado y belleza" },
  { id: 4, title: "Mujer - Ropa" },
  { id: 16, title: "Mujer - Calzado" },
  { id: 19, title: "Mujer - Bolsos" },
  { id: 1187, title: "Mujer - Accesorios" },
  { id: 146, title: "Mujer - Cuidado y belleza" },
  { id: 1206, title: "Hombre - Ropa - Abrigos y chaquetas" },
  { id: 76, title: "Hombre - Ropa - Camisetas y camisas" },
  { id: 32, title: "Hombre - Ropa - Trajes y blazers" },
  { id: 79, title: "Hombre - Ropa - Jerséis y sudaderas" },
  { id: 34, title: "Hombre - Ropa - Pantalones" },
  { id: 80, title: "Hombre - Ropa - Shorts" },
  { id: 85, title: "Hombre - Ropa - Pijamas" },
  { id: 84, title: "Hombre - Ropa - Bañadores" },
  { id: 30, title: "Hombre - Ropa - Ropa y accesorios deportivos" },
  { id: 92, title: "Hombre - Ropa - Disfraces y trajes especiales" },
  { id: 83, title: "Hombre - Ropa - Otras prendas" },
  { id: 257, title: "Hombre - Ropa - Vaqueros" },
  { id: 2656, title: "Hombre - Calzado - Naúticos y mocasines" },
  { id: 1233, title: "Hombre - Calzado - Botas" },
  { id: 2657, title: "Hombre - Calzado - Alpargatas" },
  { id: 2658, title: "Hombre - Calzado - Sandalias y chanclas" },
  { id: 1238, title: "Hombre - Calzado - Zapatos de vestir" },
  { id: 2659, title: "Hombre - Calzado - Pantuflas" },
  { id: 1452, title: "Hombre - Calzado - Zapatillas de deporte" },
  { id: 1242, title: "Hombre - Calzado - Zapatillas" },
  { id: 93, title: "Hombre - Accesorios - Corbatas y pañuelos" },
  { id: 94, title: "Hombre - Accesorios - Mochilas y bolsos" },
  { id: 95, title: "Hombre - Accesorios - Joyería" },
  { id: 96, title: "Hombre - Accesorios - Cinturones" },
  { id: 97, title: "Hombre - Accesorios - Relojes" },
  { id: 98, title: "Hombre - Accesorios - Gafas de sol" },
  { id: 86, title: "Hombre - Accesorios - Sombreros y gorros" },
  { id: 87, title: "Hombre - Accesorios - Bufandas y pañuelos" },
  { id: 91, title: "Hombre - Accesorios - Guantes" },
  { id: 99, title: "Hombre - Accesorios - Otros" },
  { id: 143, title: "Hombre - Cuidado y belleza - Cuidado facial" },
  {
    id: 2055,
    title: "Hombre - Cuidado y belleza - Utensilios de aseo y belleza",
  },
  { id: 140, title: "Hombre - Cuidado y belleza - Cuidado del cabello" },
  { id: 141, title: "Hombre - Cuidado y belleza - Cuidado corporal" },
  { id: 142, title: "Hombre - Cuidado y belleza - Cuidado de manos y uñas" },
  { id: 145, title: "Hombre - Cuidado y belleza - Perfumes" },
  { id: 144, title: "Hombre - Cuidado y belleza - Maquillaje" },
  { id: 1863, title: "Hombre - Cuidado y belleza - Sets" },
  { id: 968, title: "Hombre - Cuidado y belleza - Otros" },
  { id: 1037, title: "Mujer - Ropa - Abrigos y cazadoras" },
  { id: 13, title: "Mujer - Ropa - Jerséis y sudaderas" },
  { id: 8, title: "Mujer - Ropa - Trajes y blazers" },
  { id: 10, title: "Mujer - Ropa - Vestidos" },
  { id: 11, title: "Mujer - Ropa - Faldas" },
  { id: 12, title: "Mujer - Ropa - Camisetas y tops" },
  { id: 183, title: "Mujer - Ropa - Vaqueros" },
  { id: 9, title: "Mujer - Ropa - Pantalones y leggings" },
  { id: 15, title: "Mujer - Ropa - Shorts" },
  { id: 1035, title: "Mujer - Ropa - Monos" },
  { id: 28, title: "Mujer - Ropa - Ropa de baño" },
  { id: 29, title: "Mujer - Ropa - Lencería y pijamas" },
  { id: 1176, title: "Mujer - Ropa - Premamá" },
  { id: 73, title: "Mujer - Ropa - Ropa deportiva" },
  { id: 1782, title: "Mujer - Ropa - Disfraces y trajes especiales" },
  { id: 18, title: "Mujer - Ropa - Otras prendas" },
  { id: 1049, title: "Mujer - Calzado - Botas" },
  { id: 2623, title: "Mujer - Calzado - Zuecos y mules" },
  { id: 1909, title: "Mujer - Calzado - Zapatos planos" },
  { id: 2624, title: "Mujer - Calzado - Sandalias y chanclas" },
  { id: 543, title: "Mujer - Calzado - Tacones" },
  { id: 215, title: "Mujer - Calzado - Pantuflas" },
  { id: 2630, title: "Mujer - Calzado - Zapatillas de deporte" },
  { id: 2632, title: "Mujer - Calzado - Zapatillas" },
  { id: 156, title: "Mujer - Bolsos - Bolsos de mano" },
  { id: 158, title: "Mujer - Bolsos - Bolsos de hombro" },
  { id: 157, title: "Mujer - Bolsos - Mochilas" },
  { id: 552, title: "Mujer - Bolsos - Bolsos tote" },
  { id: 159, title: "Mujer - Bolsos - Bolsos de fiesta" },
  { id: 160, title: "Mujer - Bolsos - Monederos y carteras" },
  { id: 161, title: "Mujer - Bolsos - Neceseres" },
  { id: 1784, title: "Mujer - Bolsos - Satchels" },
  { id: 1848, title: "Mujer - Bolsos - Riñoneras" },
  { id: 1493, title: "Mujer - Bolsos - Bolsos estampados o bordados" },
  { id: 1849, title: "Mujer - Bolsos - Bolsas de deporte" },
  { id: 1850, title: "Mujer - Bolsos - Maletas" },
  { id: 155, title: "Mujer - Bolsos - Otros" },
  { id: 21, title: "Mujer - Accesorios - Joyería" },
  { id: 22, title: "Mujer - Accesorios - Relojes" },
  { id: 20, title: "Mujer - Accesorios - Cinturones" },
  { id: 26, title: "Mujer - Accesorios - Gafas de sol" },
  { id: 89, title: "Mujer - Accesorios - Bufandas y pañuelos" },
  { id: 90, title: "Mujer - Accesorios - Guantes" },
  { id: 88, title: "Mujer - Accesorios - Sombreros y gorros" },
  { id: 1123, title: "Mujer - Accesorios - Accesorios de cabello" },
  { id: 1221, title: "Mujer - Accesorios - Accesorios de tecnología" },
  { id: 1851, title: "Mujer - Accesorios - Paraguas" },
  { id: 1852, title: "Mujer - Accesorios - Llaveros" },
  { id: 1140, title: "Mujer - Accesorios - Otros" },
  { id: 964, title: "Mujer - Cuidado y belleza - Maquillaje" },
  { id: 152, title: "Mujer - Cuidado y belleza - Perfume" },
  { id: 948, title: "Mujer - Cuidado y belleza - Cuidado facial" },
  { id: 1906, title: "Mujer - Cuidado y belleza - Accesorios de belleza" },
  { id: 1264, title: "Mujer - Cuidado y belleza - Cuidado de las manos" },
  { id: 960, title: "Mujer - Cuidado y belleza - Manicura" },
  { id: 956, title: "Mujer - Cuidado y belleza - Cuidado corporal" },
  { id: 1902, title: "Mujer - Cuidado y belleza - Cuidado del cabello" },
  { id: 153, title: "Mujer - Cuidado y belleza - Otros productos de belleza" },
];
const getModalStyle = (isMobile) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobile ? "100%" : "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
});
const sendTestWebhook = async (webhookURL) => {
  try {
    const response = await fetch(webhookURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        content: 'El webhook funciona correctamente! 🎉',
        username: 'VintXpress Dashboard Test', // Optional: You can set a custom username for the webhook message
        avatar_url: 'https://pbs.twimg.com/profile_images/1704078633067810816/7Y9XtVZA_400x400.jpg', // Optional: You can set a custom avatar for the webhook message
      })
    });

    if (!response.ok) {
      throw new Error('Failed to send test webhook');
    }

  } catch (error) {
    alert('Error enviando el webhook. Revisa el URL e inténtalo de nuevo.');
  }
};
const UpdateModal = (props) => {
  const auth = useAuth();
  const { filter, onClose, open, callback } = props;

  //console.log(filter.categories);
  const theme = useTheme();

  // Check if it's a mobile viewport.
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [error, setError] = useState(null);
  const [selectedCategoryTitles, setSelectedCategoryTitles] = useState([]);

  // Define the dialog width

  // Invert the statusMapping for reverse lookup
  const invertMapping = (map) => {
    return Object.entries(map).reduce((acc, [key, value]) => {
      acc[value] = key;
      return acc;
    }, {});
  };

  const areFiltersSame = (oldFilter, newFilter) => {
    return (
      oldFilter.maxPrice === newFilter.maxPrice &&
      oldFilter.minStars === newFilter.minStars &&
      oldFilter.status === newFilter.status &&
      oldFilter.webhook === newFilter.webhook &&
      oldFilter.categories === newFilter.categories &&
      JSON.stringify(oldFilter.positiveKeywords) ===
        JSON.stringify(newFilter.positiveKeywords) &&
      JSON.stringify(oldFilter.negativeKeywords) ===
        JSON.stringify(newFilter.negativeKeywords)
    );
  };

  const invertedStatusMapping = invertMapping(statusMapping);

  // Initialize localFilter with the default value
  const [localFilter, setLocalFilter] = useState({
    ...filter,
    status: invertedStatusMapping[filter.status] || 0,
  });

  // Use useEffect to update localFilter whenever the filter prop changes
  useEffect(() => {
    setLocalFilter({
        ...filter,
        status: parseInt(invertedStatusMapping[filter.status]) || 0,
        categories: filter.selected_categories || [],
    });
    updateSelectedCategoryTitles(); // Add this line
}, [filter]);

const updateSelectedCategoryTitles = () => {
  const matchingCategories = categoriesList.filter((category) =>
      filter.categories.includes(String(category.id))
  );
  const titles = matchingCategories.map((cat) => cat.title);
  setSelectedCategoryTitles(titles);
};
  const [newPositiveKeyword, setNewPositiveKeyword] = useState("");
  const [newNegativeKeyword, setNewNegativeKeyword] = useState("");

  const handleKeywordDelete = (type, keyword) => {
    setLocalFilter({
      ...localFilter,
      [type]: localFilter[type].filter((kw) => kw !== keyword),
    });
  };

  const handleAddPositiveKeyword = () => {
    if (newPositiveKeyword) {
      setLocalFilter({
        ...localFilter,
        positiveKeywords: [...localFilter.positiveKeywords, newPositiveKeyword],
      });
      setNewPositiveKeyword("");
    }
  };

  const handleAddNegativeKeyword = () => {
    if (newNegativeKeyword) {
      setLocalFilter({
        ...localFilter,
        negativeKeywords: [...localFilter.negativeKeywords, newNegativeKeyword],
      });
      setNewNegativeKeyword("");
    }
  };
  const handleCategoryChange = (event, newValue) => {
    setSelectedCategoryTitles(newValue);
  };

  const handleUpdate = async (event) => {
    event.preventDefault(); // Prevents the form from submitting in the traditional way
    const updatedCategories = categoriesList
      .filter((category) => selectedCategoryTitles.includes(category.title))
      .map((category) => category.id);

    const updatedFilter = {
      minStars: localFilter.minStars,
      maxPrice: localFilter.maxPrice,
      status: localFilter.status,
      webhook: localFilter.webhook,
      categories: updatedCategories,
      positiveKeywords: localFilter.positiveKeywords,
      negativeKeywords: localFilter.negativeKeywords,
    };
    if (areFiltersSame(updatedFilter, filter)) {
      onClose();
      return;
    }

    const response = await vintedService.updateFilterTask(
      filter.id,
      updatedFilter,
      auth.token
    );
    //console.log(status);
    if (response.status === 201) {
      callback(true);
      onClose();
    } else if (response.error) {
      setError(response.error);
      setTimeout(() => {
        setError(null);
      }, 1500);
    }

    else {
      setError("Error al añadir el filtro. Inténtalo de nuevo.");
      setTimeout(() => {
        setError(null);
      }, 1500);
    }
    
  };

  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={handleUpdate}>
        <Card sx={getModalStyle(isMobile)}>
          <CardHeader title="Editar Filtro" />
          {error && (
            <Box
              sx={{
                position: "absolute",
                top: "64px",
                left: "0",
                right: "0",
                margin: "auto",
                zIndex: "1",
              }}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%" }}
                spacing={2}
              >
                <Alert severity="error">{error}</Alert>
              </Stack>
            </Box>
          )}
          <CardContent style={{ maxHeight: "67vh", overflowY: "auto" }}>


            <TextField
              fullWidth
              label="Marca"
              value={localFilter.brand}
              disabled
              margin="normal"
            />
      <Autocomplete
        multiple
        id="categories-autocomplete"
        options={categoriesList.map((category) => category.title)}
        value={selectedCategoryTitles}
        onChange={handleCategoryChange}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Categories" />
        )}
      />

            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel htmlFor="status-label">Estado</InputLabel>
              <Select
                label="Status"
                labelId="status-label"
                fullWidth
                value={localFilter.status}
                onChange={(e) =>
                  setLocalFilter({
                    ...localFilter,
                    status: parseInt(e.target.value),
                  })
                }
              >
                {Object.entries(statusMapping).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              type="number"
              label="Precio Máximo (€)"
              value={localFilter.maxPrice === 0 ? "" : localFilter.maxPrice}
              onChange={(e) => {
                const val =
                  e.target.value === "" ? 0 : parseInt(e.target.value);
                setLocalFilter({ ...localFilter, maxPrice: val });
              }}
              margin="normal"
            />
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              name="minStars"
              label="Estrellas mínimas del vendedor"
              type="number"
              inputProps={{
                step: 0.5,
                min: 0,
                max: 5,
              }}
              value={localFilter.minStars === 0 ? "" : localFilter.minStars}
              onChange={(e) => {
                const val =
                  e.target.value === "" ? 0 : parseFloat(e.target.value);
                const clampedVal = Math.max(0, Math.min(val, 5));
                setLocalFilter({ ...localFilter, minStars: clampedVal });
              }}
            />

            {/* Positive Keywords */}
            <div style={{ marginTop: "10px" }}>
              <TextField
                fullWidth
                label="Keywords Positivas"
                value={newPositiveKeyword}
                onChange={(e) => setNewPositiveKeyword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleAddPositiveKeyword}
                      >
                        Añadir
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <Grid container spacing={1} style={{ marginTop: "10px" }}>
                {localFilter.positiveKeywords.map((keyword) => (
                  <Grid item key={keyword}>
                    <Chip
                      label={keyword}
                      color="success"
                      onDelete={() =>
                        handleKeywordDelete("positiveKeywords", keyword)
                      }
                      variant="outlined"
                      size="small"
                      style={{ borderRadius: "20%" }}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
            {/* Negative Keywords */}
            <div style={{ marginTop: "10px" }}>
              <TextField
                fullWidth
                label="Keywords Negativas"
                value={newNegativeKeyword}
                onChange={(e) => setNewNegativeKeyword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleAddNegativeKeyword}
                      >
                        Añadir
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <Grid container spacing={1} style={{ marginTop: "10px" }}>
                {localFilter.negativeKeywords.map((keyword) => (
                  <Grid item key={keyword}>
                    <Chip
                      label={keyword}
                      color="success"
                      onDelete={() =>
                        handleKeywordDelete("negativeKeywords", keyword)
                      }
                      variant="outlined"
                      size="small"
                      style={{ borderRadius: "20%" }}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
            <TextField
              fullWidth
              label="Webhook"
              value={localFilter.webhook}
              onChange={(e) =>
                setLocalFilter({ ...localFilter, webhook: e.target.value })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => sendTestWebhook( localFilter.webhook)}
                    >
                      Probar
                    </Button>
                  </InputAdornment>
                ),
              }}
              margin="normal"
              style={{ marginBottom: "16px" }} // Add margin to create space
            />
          </CardContent>
          <CardActions
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#fff",
              padding: "16px",
              position: "sticky", // Keep the buttons at the bottom
              bottom: 0,
            }}
          >
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={!localFilter.webhook}
            >
              Editar Filtro
            </Button>
            <Button color="error" variant="contained" onClick={onClose}>
              Cancelar
            </Button>
          </CardActions>
        </Card>
      </form>
    </Modal>
  );
};

export default UpdateModal;
