import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import CircularProgress from '@mui/material/CircularProgress';

const CallbackPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSubscriptionError, setShowSubscriptionError] = useState(false);

  let auth = useAuth();

  useEffect(() => {
    const handleCallback = async () => {
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get('code');
      
      if (code) {
        try {
          await auth.signin(code, () => navigate('/'));
        } catch (error) {
            setShowSubscriptionError(true);
            setTimeout(() => {
                navigate('/login');
            }, 5000);

        } finally {
          setIsLoading(false);
        }
      } else {
        console.error('No code provided in callback URL');
        setIsLoading(false);
      }
    };

    handleCallback();
  }, [location]);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        {isLoading ? (
            <CircularProgress />
        ) : showSubscriptionError ? (
            <div>
                <p>Compra el acceso a VintXpress Premium en <a href="https://whop.com/vintxpress/">https://whop.com/vintxpress/</a></p>
            </div>
        ) : errorMessage ? (
            <p>{errorMessage}</p>
        ) : null}
    </div>
  );
};

export default CallbackPage;
