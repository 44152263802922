import React, { useEffect, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import autobuyService from "../../services/autobuy";
import { Box, Alert, Stack, Container, Button, CircularProgress, Typography, Link} from '@mui/material';
import vintedService from "../../services/vinted";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}



const AutobuyPage = () => {
  const auth = useAuth();
  const query = useQuery();
  const itemId = query.get('item');
  const sellerId = query.get('seller');
  const [userToken, setUserToken] = useState('');
  const [webhook, setWebhook] = useState('');
  const [message, setMessage] = useState({ text: null, type: '' }); // text can now be a React node
  const [paymentLink, setPaymentLink] = useState('');
  const [loading, setLoading] = useState({ isLoading: false, message: '' });

 // type can be 'error' or 'success'

  useEffect(() => {
    if (!auth.user) {
      setMessage({ text: "You need to be logged in to use this feature.", type: 'error' });
      return;
    }
    if (!itemId || !sellerId) {
      setMessage({ text: "Missing autobuy fields: itemId and sellerId are required.", type: 'error' });
      return;
    }

    const fetchUserToken = async () => {
      try {
        const tokens = await vintedService.getAllTokensTask(auth.token); // Adjust the service call if needed
        const mainToken = tokens.find(tokenData => tokenData.isMain);
        if (!mainToken) {
          setMessage({
            text: (
              <span>
                No se ha encontrado ningun token principal, por favor introduce un token de vinted {' '}  
                <a href="https://vintxpress.com/vinted/tokens" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'underline' }}>
                   aquí
                </a>
              </span>
            ), 
            type: 'error'
          });
          return;
        }
        setWebhook(mainToken.webhook);
        setUserToken(mainToken.value);
        setMessage({ text: "Token correcto", type: 'success' });
      } catch (error) {
        console.error("Failed to fetch tokens:", error);
        setMessage({ text: "Failed to fetch user tokens.", type: 'error' });
      }
    };

    fetchUserToken();
  }, [auth.user, auth.token, itemId, sellerId]);

  useEffect(() => {
    if (!auth.user || !userToken || !itemId || !sellerId) {
      // Error handling is already managed in the first useEffect.
      return;
    }

    setLoading({ isLoading: true, message: 'Comprando item...' }); // Start loading with message

    const buyItemData = {
        itemId: itemId,
        sellerId: sellerId,
        userToken: userToken,
        webhook: webhook,
        authToken: auth.token,
    };

    autobuyService.buyItem(buyItemData)
    .then(responseData => {
      setLoading({ isLoading: false, message: '' }); // Stop loading and clear message
      if (responseData.success) {
        setMessage({ text: `${responseData.message}`, type: 'success' });
        setPaymentLink(responseData.pay_link);
      } else {
        setMessage({ text: `${responseData.message}`, type: 'error' });
        setPaymentLink('');
      }
    })
    .catch(error => {
      setLoading({ isLoading: false, message: '' }); // Ensure to stop loading and clear message on error
      console.error('Error comprando el item:', error);
      setMessage({ text: "Error comprando el item", type: 'error' });
      setPaymentLink('');
    });
    
  
  }, [auth.user, itemId, sellerId, userToken , auth.token]);

  if (!auth.user) {
    return <Navigate to="/login" />;
  }
  return (
    <Box sx={{ 
      width: '100vw', 
      height: '100vh', 
      backgroundColor: '#000000', 
      margin: 0, 
      padding: 0, 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'space-between', // Adjusted to space-between to push footer to bottom
      alignItems: 'center' 
    }}>
      <Container maxWidth="sm" sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        zIndex: 1100 
      }}>
        <Stack
          sx={{
            position: 'relative', // Changed from absolute to relative
            width: '80%',
            alignItems: 'center',
            mt: 7, // Added margin top for spacing
          }}
          spacing={2}
        >
          {/* Content */}
          {loading.isLoading ? (
            <Stack direction="row" spacing={2} alignItems="center">
              <Alert severity="info">{loading.message}</Alert>
              <CircularProgress />
            </Stack>
          ) : message.text ? (
            <Alert severity={message.type}>{message.text}</Alert>
          ) : null}
  
          {!loading.isLoading && paymentLink && (
            <Button
              variant="contained"
              sx={{ backgroundColor: '#4CAF50' }}
              onClick={() => window.location.href = paymentLink}
            >
              Acabar el Pago
            </Button>
          )}
        </Stack>
      </Container>
      {/* Footer */}
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ mt: 2, mb: 2 }} // Adjusted margins for spacing
      >
        {"Copyright © "}
        <Link color="inherit" href="#">
          VintXpress Autobuy
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Box>
  );
  
          };  



export default AutobuyPage;
